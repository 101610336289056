﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../../core/models/service-response.model';
import { NewServiceResponse } from 'app/core/models/new-service-response.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'app/app-config.service';


@Injectable()
export class UserTokenService {

   // private loginUrl: string;
  //  addRegistrationUrl: string;
  //  forgotPasswordUrl: string;
   // otpUrl: string;
  //  userConfirmationByOTPUrl: string;
    regMobileNo: string;
    regEmail: string;
    regName: string;
    regPassword: string;
    FacebookUserId: string;

    constructor(private _http: HttpClient, private _config: AppConfigService) {

//        this.loginUrl = `${_config.getConfig().WEB_API}api/merchant/API-AdminLogin`;
 //       this.addRegistrationUrl = `${_config.getConfig().WEB_API}api/user/registration`;
    //    this.forgotPasswordUrl = `${_config.getConfig().WEB_API}api/merchant/API-ForgotPassword`;
  //      this.otpUrl = `${_config.getConfig().WEB_API}api/merchant/VerificationOTPCode`;
   //     this.userConfirmationByOTPUrl = `${_config.getConfig().WEB_API}api/merchant/VerifyUser`;
    }

    getLoginInfoDetails(loginModel: any): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(`${this._config.getConfig().WEB_API}api/merchant/API-AdminLogin`, loginModel);
    }

    addRegistration(data: any): Observable<any> {
        return this._http.post<ServiceResponse>(`${this._config.getConfig().WEB_API}api/user/registration`, data);
    }

    forgotPassword(email: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(`${this._config.getConfig().WEB_API}api/merchant/API-ForgotPassword?EmailId=` + email);
    }

    otpConfirmation(mobileNo: string, email: string, name: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(this._config.getConfig().WEB_API+'api/merchant/VerificationOTPCode?MobileNo=' + mobileNo + '&EmailID=' + email + '&Name=' + name);
    }

    userConfirmationByOTP(email: string, otpCode: string): Observable<NewServiceResponse> {

        return this._http.get<NewServiceResponse>(this._config.getConfig().WEB_API+'api/merchant/VerifyUser?EmailID=' + email + '&OTPUser=' + otpCode);
    }
}