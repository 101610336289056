
import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constant';

import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'app/app-config.service';


@Injectable()
export class CustomerService {

    //private customerDataUrl: string;


    constructor(private _http: HttpClient, 
        private _configuration: AppConfigService) {
        //    this.customerDataUrl = `${_configuration.getConfig().WEB_API}api/admin/Customer/customerList`;
    }

    getCustomerData(params: any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/admin/Customer/customerList`, params);
    }
    getCustomerList(params: any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/admin/customer/customerListV1`, params);
    }
    getCustomerExportList(params: any): Observable<any> {
        return this._http.post<any>(`${this._configuration.getConfig().WEB_API}api/admin/customer/ExportList`, params);
    }
}